import React from 'react';
import { useParams } from 'react-router-dom';
import internshipsData from '../Data/internshipData.json';
const Information = () => {
    const { keyword } = useParams();
    const internshipInfo = internshipsData[keyword];
    if (!internshipInfo) {
        return <div>No information found for this internship.</div>;
    }

    return (
        <div className='container'>
            <h1 className='fs-3 text'>{internshipInfo.name}</h1>
            <p>{internshipInfo.overview}</p>

            <div className='row'>
                <div className='col-md-6 col-lg-6'>
                    <p className='fw-semibold'>Overview</p>
                    <ul>
                        <li><strong>Internship Name:</strong> {internshipInfo.name}</li>
                        <li><strong>Duration:</strong> 4 Weeks (1 Month) or 2 Months (You can choose different while applying.)</li>
                        <li><strong>Tasks and Technologies:</strong> {internshipInfo.task}</li>
                    </ul>

                    <p className='fw-semibold'>What to Expect</p>
                    <ul>
                        {internshipInfo.whatToExpect.map((expectation, index) => (
                            <li key={index}>{expectation}</li>
                        ))}
                    </ul>

                    <p className='fw-semibold'>Internship Objectives</p>
                    <ul>
                        {internshipInfo.objectives.map((objective, index) => (
                            <li key={index}>{objective}</li>
                        ))}
                    </ul>

                    <p className='fw-semibold'>Who Should Apply</p>
                    <ul>
                        {internshipInfo.whoShouldApply.map((applicant, index) => (
                            <li key={index}>{applicant}</li>
                        ))}
                    </ul>

                    <p className='fw-semibold'>Benefits</p>
                    <ul className='fw-semibold' style={{ listStyle: 'none' }}>
                        <li><i className="bi bi-envelope-paper"></i> Offer Letter</li>
                        <li><i className="bi bi-check2-circle"></i> Letter of Recommendation</li>
                        <li><i className="bi bi-envelope-paper-heart"></i> Completion Certificate</li>
                        <li><i className="bi bi-patch-check"></i> Unique Verification Code on Certificate</li>
                    </ul>
                </div>

                <div className='col-md-6 col-lg-6 d-flex justify-content-center align-items-center'>
                    <img src={internshipInfo.img} className='img-fluid' alt="Internship Image" />
                </div>

            </div>
            <div className='border-top text-center pt-3'>
                <a href="/contact" className='btn btn-lg btn-primary mx-2'><i className="bi bi-person-lines-fill"></i> Contact</a>
                <a href="https://forms.gle/fkAD8UPzLhK9ZTkP8" className='btn btn-lg btn-success'><i className="bi bi-rocket-takeoff"></i> Apply</a>
            </div>
        </div>
    );
}

export default Information;