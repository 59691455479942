import React from 'react';
import Card from './card';

const CardContainer = () => {
    const internshipDetails = [
        {
            name: 'Web Development Internship',
            img: 'https://cdni.iconscout.com/illustration/premium/thumb/frontend-developer-7464456-6109659.png',
            keyword: 'web'
        },
        {
            img: 'https://static.vecteezy.com/system/resources/thumbnails/011/153/368/small/3d-website-developer-working-on-laptop-illustration-png.png',
            name: 'Fullstack Development Internship',
            keyword: 'fullstack'
        },
        {
            img: 'https://cdni.iconscout.com/illustration/premium/thumb/ui-ux-design-7364652-6024623.png',
            name: 'UI/UX Internship',
            keyword: 'uiux'
        },
        {
            img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/66/Android_robot.png/504px-Android_robot.png',
            name: 'Android Development Internship',
            keyword: 'android'
            },
        {
            img: 'https://static.vecteezy.com/system/resources/thumbnails/012/697/295/small/3d-python-programming-language-logo-free-png.png',
            name: 'Python Development Internship',
            keyword: 'python'
        },
        {
            img: 'https://upload.wikimedia.org/wikipedia/commons/b/bb/Java-logo.png',
            name: 'Java Internship',
            keyword: 'java'
        },
        {
            img: 'https://upload.wikimedia.org/wikipedia/commons/3/32/C%2B%2B_logo.png',
            name: 'C++ Internship',
            keyword: 'c++'
        },
        {
            img: 'https://upload.wikimedia.org/wikipedia/commons/1/19/C_Logo.png',
            name: 'C Internship',
            keyword: 'c'
        },
        {
            img: 'https://png.pngtree.com/png-vector/20230407/ourmid/pngtree-deep-learning-line-icon-vector-png-image_6688986.png',
            name: 'Machine Learning Internship',
            keyword: 'ml'
        },
        {
            img: 'https://png.pngtree.com/png-clipart/20230411/original/pngtree-artificial-intelligence-robot-illustration-png-image_9047239.png',
            name: 'Artificial Intalligene Internship',
            keyword: 'ai'
        },
        {
            img: 'https://cdn-icons-png.flaticon.com/512/4824/4824797.png',
            name: 'Data Science Internship',
            keyword: 'dataScience'
        }
    ];
    return (
        <div id='interships' className={`container-fluid`}>
            <hr />
            <h3 className='text-center'>Internship Domains</h3>
            <div className='row mt-1'>
                {internshipDetails.map((internship, index) => (
                    <div key={index} className='col-md-4 col-lg-4 mt-4 d-flex'>
                        <div className={`card h-100 w-100`}>
                            <Card details={internship} keyword={internship.keyword} />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CardContainer;
