import React, { Component } from 'react'
import CardContainer from './internship/CardContainer'
import { Link } from 'react-router-dom'
export default class Home extends Component {
    render() {
        return (
            <div>
                <div className='container'>
                    <div className="row align-items-center vh-100">
                        <div className="col-md-6">
                            <img className="img-fluid" src="https://cdn.pixabay.com/photo/2019/10/09/07/28/development-4536630_1280.png" alt="" />
                        </div>
                        <div className="col-md-6">
                            <div className="title-container text-center">
                                <h1 className="title">Welcome to Internstudio</h1>
                                <p className="tagline">Unlock Your Potential with Our Diverse Internship Opportunities!</p>
                                <Link className="btn btn-primary btn-lg btn-rounded fw-semibold" id="apply" to={'/internships'}> Apply <i className="bi bi-rocket-takeoff"></i></Link>
                            </div>
                        </div>
                    </div>

                    {/* Internship Cards  */}
                    <CardContainer />
                </div>
            </div>
        )
    }
}
