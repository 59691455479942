import React from 'react';

const TermsConditionsPage = () => {
    return (
        <div className="mt-4 container">
            <div className='row'>
                <div md={8} className="mx-auto col">
                    <div className='card'>
                        <div className='card-body'>
                            <h3 className="text-center mb-4">Terms and Conditions</h3>
                            <p className='fs-5'>Welcome to Internstudio! By accessing and utilizing our services, you agree to comply with the following terms and conditions:</p>

                            <ol>
                                <ul>
                                    <li><strong>Non-Refundable Payment:</strong> Payments made to Internstudio are non-refundable.</li>
                                    <li><strong>Beware of Fraudulent Offers:</strong> Internstudio is committed to providing legitimate internship opportunities.</li>
                                    <li><strong>Terms of Service Changes:</strong> Internstudio reserves the right to modify, amend, or update these terms and conditions.</li>
                                    <li><strong>Service Access:</strong> Upon payment confirmation for our services.</li>
                                    <li><strong>Privacy and Data Security:</strong> Internstudio values your privacy.</li>
                                    <li><strong>Perks and Benefits:</strong> Any perks, benefits, or services provided by Internstudio.</li>
                                    <li><strong>No Training Component:</strong> Please note that Internstudio's internship programs do not include formal training.</li>
                                    <li><strong>Compliance:</strong> By using Internstudio's services, you agree to comply with all applicable laws.</li>
                                    <li><strong>Dispute Resolution:</strong> Any disputes or disagreements regarding our services or these terms and conditions.</li>
                                    <li><strong>Contact Information:</strong> For any inquiries or concerns regarding these terms and conditions, please contact us at <a href='mailto:team.internstudio@gmail.com'>team.internstudio@gmail.com</a> .</li>
                                </ul>

                            </ol>

                            <p>By continuing to use Internstudio's services, you acknowledge that you have read, understood, and agreed to these terms and conditions.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default TermsConditionsPage;
