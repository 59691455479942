import React, { Component } from 'react';

export default class Contact extends Component {
    render() {
        return (
            <div className='container'>
                <h1 className='fs-3 text text-center'>Contact Us</h1>
                <div className='row'>
                    <div className='col-md-6 col-lg-6 d-none d-md-block d-flex justify-content-center align-items-center'>
                        <img src="https://i.ibb.co/dpjY52Y/3157914.png" style={{ height: "50%", margin: "auto auto !important" }} alt="" />
                    </div>

                    <div className='col-md-6 col-lg-6'>
                        <form className="mt-5">
                            <div className='row'>
                                <div className='mb-3 col-md-6'>
                                    <label htmlFor='name' className='form-label'>Name</label>
                                    <input type='text' className='form-control' id='name' />
                                </div>
                                <div className='mb-3 col-md-6'>
                                    <label htmlFor='contact' className='form-label'>Contact</label>
                                    <input type='text' className='form-control' id='contact' />
                                </div>
                            </div>
                            <div className='mb-3'>
                                <label htmlFor='email' className='form-label'>Email</label>
                                <input type='email' className='form-control' id='email' />
                            </div>
                            <div className='mb-3'>
                                <label htmlFor='subject' className='form-label'>Subject</label>
                                <input type='text' className='form-control' id='subject' />
                            </div>
                            <div className='mb-3'>
                                <label htmlFor='message' className='form-label'>Message</label>
                                <textarea className='form-control' id='message' rows='5'></textarea>
                            </div>
                            <div className='mb-3'>
                                <small>We'll reach you within the next 24 hours.</small>
                            </div>
                            <div className='text-center'>
                                <a type='reset' className='btn  btn-danger mx-2 '><i className="bi bi-x-octagon"></i> Reset</a>
                                <a type='submit' className='btn  btn-success'><i className="bi bi-check-circle"></i> Submit</a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}
