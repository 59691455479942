import React, { Component } from 'react'

export default class Footer extends Component {
    render() {
        return (
            <div className="container">
                <footer className="row mt-5 border-top">
                    <ul className="nav col-md-6 justify-content-center w-100">
                        <li className="nav-item "><a href="/" className="nav-link px-2  text-muted">Home</a></li>
                        <li className="nav-item"><a href="/internships" className="nav-link px-2 text-muted">Internships</a></li>
                        <li className="nav-item"><a href="/about" className="nav-link px-2 text-muted">About</a></li>
                        <li className="nav-item"><a href="/contact" className="nav-link px-2 text-muted">Contact</a></li>
                        <li className="nav-link"><a href="/terms&conditions" className='text-muted nav-link'>Terms & Conditions</a></li>

                    </ul>
                    <p className="col-md-6 text-center w-100">All rights reserved 2024 |<b> © Internstudio</b></p>
                </footer>
            </div>
        )
    }
}
