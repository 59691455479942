import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class Card extends Component {
    render() {
        const { details, keyword } = this.props;
        return (
            <div className='card-body text-center'>
                <img src={details.img} style={{ width: '160px', margin: 'auto' }} alt="" />
                <hr />
                <h1 className='fs-5 text'>{details.name}</h1>
                <p className='text-grey'>{details.description}</p>
                <div className='text-center mt-3'>
                    <Link className='btn btn-primary fw-semibold mx-2' to={`/info/${keyword}`}><i className="bi bi-info-circle"></i> Info</Link>
                    <a className='btn btn-success fw-semibold'  href='https://forms.gle/fkAD8UPzLhK9ZTkP8' target='_blank'><i className="bi bi-rocket-takeoff"></i> Apply</a>
                </div>
            </div>
        );
    }
}
