import React, { Component } from 'react';

export default class About extends Component {
  render() {
    return (
      <div className="">
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <h2 className="text-center mt-5">Welcome to Internstudio: Empowering Future Innovators</h2>
            <p>
              At Internstudio, we are passionate about nurturing the next generation of tech innovators. Our mission is to bridge the gap between academic learning and real-world industry experience by offering dynamic internship opportunities to talented students.
            </p>
            <h4 className="mt-5">Our Vision</h4>
            <p>
              We envision a future where every student has the opportunity to gain hands-on experience in the ever-evolving tech landscape. By providing immersive internship programs, we aim to equip students with the skills, knowledge, and confidence needed to thrive in the competitive world of technology.
            </p>
            <h4 className="mt-5">Why Choose Internstudio Internships?</h4>
            <ul>
              <li>Industry-Driven Learning: Our internships are designed in collaboration with industry experts, ensuring that students learn relevant skills and technologies that are in demand in the job market.</li>
              <li>Hands-On Experience: We believe in learning by doing. Our internships offer hands-on projects and real-world challenges, giving students a taste of what it's like to work in the tech industry.</li>
              <li>Mentorship and Guidance: Each intern is paired with a mentor who provides personalized guidance, support, and feedback throughout the internship journey.</li>
              <li>Networking Opportunities: Interns have the chance to connect with professionals, build valuable networks, and gain insights into different career paths within the tech industry.</li>
            </ul>
            <h4 className="mt-5">Our Internship Programs</h4>
            <p>We offer internship programs in various tech domains, including but not limited to:</p>
            <ul className='fw-semibold'>
              <li><i className="bi bi-filetype-html"></i> Web Development</li>
              <li><i className="bi bi-android2"></i> Mobile App Development</li>
              <li><i className="bi bi-clipboard2-data"></i> Data Science and Analytics</li>
              <li><i className="bi bi-window-fullscreen"></i> UI/UX Design</li>
              <li><i className="bi bi-shield-lock"></i> Cybersecurity</li>
              <li><i className="bi bi-cloud"></i> Cloud Computing</li>
            </ul>
            <p>
              Our programs cater to students at different levels, from beginners to advanced learners, and are designed to be immersive, challenging, and rewarding.
            </p>
            <h4 className="mt-5">Registered Under MSME | AICTE Approved Internships</h4>
            <p>
              Internstudio is registered under MSME, and our internships are approved by AICTE internship portal, ensuring high-quality and industry-relevant learning experiences for our interns.
            </p>
            <h4 className="mt-5">Join Us in Shaping the Future</h4>
            <p>
              Whether you're a student looking to kickstart your tech career or an industry professional interested in mentoring the next generation, Internstudio welcomes you to be part of our journey in empowering future innovators.
            </p>
            <h4 className="mt-5">Get in Touch</h4>
            <p>
              Ready to embark on an exciting internship journey with Internstudio? Contact us today to learn more about our internship programs, application process, and upcoming opportunities. Let's code the future together!
            </p>
            <div className='text-center mt-5'>
              <a href="/contact" className='btn btn-lg btn-primary mx-2'><i className="bi bi-person-lines-fill"></i> Contact</a>
              <a href="/internships" className='btn btn-lg btn-success'><i className="bi bi-rocket-takeoff"></i> Apply</a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
