import Home from "./components/Home";
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/style.css';
import About from './components/common/about'
import Header from './components/common/header'
import Footer from './components/common/footer'
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Information from "./components/info";
import Contact from "./components/common/contact";
import CardContainer from "./components/internship/CardContainer";
import TermsConditionsPage from "./components/Tearms&Conditions";
const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/info/:keyword",
    element: <Information />,
  },
  {
    path: "/internships",
    element: <CardContainer />,
  },
  {
    path: "/terms&conditions",
    element: <TermsConditionsPage />,
  }
]);

function App() {
  return (
    <div className="container-fluid">
      <Header />
      <RouterProvider router={router} />
      <Footer />
    </div>
  );
}

export default App;