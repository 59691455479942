import React, { Component } from 'react';

export default class Header extends Component {
  render() {
    return (
      <nav className="navbar navbar-expand-lg container-fluid">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <img src='https://i.ibb.co/PYwZR8s/black-logo.png' className='img-fluid' style={{height:'35px'}} alt="Internstudio Logo" />
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0 fs-5">
              <li className="nav-item">
                <a className="nav-link" href="/"><i className="bi bi-house"></i> Home</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/internships"><i className="bi bi-tag"></i> Internships</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/contact"><i className="bi bi-telephone"></i> Contact</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/terms&conditions"><i className="bi bi-telephone"></i> Terms & Conditions</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/about"><i className="bi bi-briefcase"></i> About</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}